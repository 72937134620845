<template>
  <header class="e-header">
    <!-- <div v-if="counselingLogoLink && !loadLogoFailed" class="e-brand e-brand--custom">
      <el-tooltip
        :content="counselingCenterName"
      >
        <img
          class="e-brand__logo e-brand__logo--img"
          :src="counselingLogoLink"
          @error="loadLogoFailed = true"
        >
      </el-tooltip>
    </div> -->
    <div class="e-brand">
        <Logo
          class="e-brand__logo e-brand__logo--svg"
        />
        <component
          :is="logo"
          class="e-brand-secondary"
        />
    </div>
    <div class="e-header__buttons">
      <div class="e-tablet-hidden e-mobile-hidden e-header__language-switch" >
        <LanguageSwitch v-if="mappedLanguages.length > 1" :languages="mappedLanguages" :language="language" @switch-language="newLanguage" />
      </div>
      <div class="e-tablet-show e-mobile-show e-header__language-switch-mobile">
        <LanguageSwitch v-if="mappedLanguages.length > 1" :languages="mappedLanguages" :language="language" @switch-language="newLanguage" :small="true"/>
      </div>
      <el-button
        name="logoutbutton"
        class="e-btn--alert e-header__button e-mobile-hidden logoutbutton"
        @click="logoutAndCatchFire"
      >
        <IconExclamation />
        <span class="e-mobile-hidden e-tablet-hidden">
          {{ $t('system.panic') }}
        </span>
      </el-button>

      <template v-if="userInfo">
        <router-link
          v-if="$route.name === 'liveChat' || $route.name === 'chatDialogs'"
          :to="{
            name: 'dialogs'
          }"
          class="e-btn e-btn--primary e-header__button e-xs-hidden"
        >
          <IconEnvelope />
          <span class="e-mobile-hidden e-tablet-hidden">
            {{ $t('system.componentEmail') }}
          </span>
        </router-link>

        <template v-else-if="!chatIsDisabled">
          <button
            v-if="!chatIsOpen"
            :disabled="!chatIsOpen"
            :aria-disabled="!chatIsOpen"
            class="e-btn is-disabled e-header__button e-xs-hidden"
          >
            <span class="e-mobile-hidden e-tablet-hidden">
              {{ $t('chat.navigation') }}
            </span>
            <chat-icon
              class="e-tablet-show"
              :show-notification="false"
            />
          </button>
          <router-link
            v-else
            :to="{
              name: 'liveChat'
            }"
            :class="[{'needs-chat-interaction': newChatMessage}, 'e-btn', 'e-btn--quaternary', 'e-header__button', 'e-xs-hidden']"
          >
            <chat-icon
              :show-notification="false"
              :notification-message="$t('chat.newMessage')"
            />
            <span class="e-mobile-hidden e-tablet-hidden">
              {{ $t('chat.navigation') }}
            </span>
          </router-link>
        </template>
      </template>
    </div>

    <nav class="e-header__nav">
      <MetaNav />
    </nav>

    <button
      v-if="!dialogOpen && !chatDialogOpen && !messageEditorOpen"
      type="button"
      name="offcanvas_toggle"
      class="e-offcanvas__toggle"
      @click="toggleOffcanvas"
    >
      <span />
      <span />
      <span />
    </button>
    <router-link
      v-if="!(!chatDialogOpen) && ($route.name === 'chatDialogs' || $route.name === 'chatDialog')"
      :to="{ name: 'chatDialogs' }"
      class="e-header__toggle"
    >
      <IconChevronLeft />
    </router-link>
    <router-link
      v-if="!(!dialogOpen && !messageEditorOpen) && ($route.name === 'dialogs' || $route.name === 'dialog' || $route.name === 'newDialog')"
      :to="{ name: 'dialogs' }"
      class="e-header__toggle"
    >
      <IconChevronLeft />
    </router-link>
    <router-link
      v-if="!(!dialogOpen && !messageEditorOpen) && ($route.name === 'sentDialog')"
      :to="{ name: 'sent' }"
      class="e-header__toggle"
    >
      <IconChevronLeft />
    </router-link>
  </header>
</template>

<script>
import VueCookie from 'vue-cookie'
import { mapGetters, mapActions } from 'vuex'
import { userActions } from 'mixins'
import { counselingCenterLogoUrl } from 'api'

import Logo from 'atoms/Logo/Logo'
import Logopausentaste from 'atoms/Logo/LogoPausentaste'
import Logo1000grad from 'atoms/Logo/Logo1000grad'
import MetaNav from 'atoms/Metanav/Metanav'
import ChatIcon from 'atoms/ChatIcon/ChatIcon'
import LanguageSwitch from 'atoms/LanguageSwitch/LanguageSwitch'

// Icons
import IconExclamation from 'assets/icons/basic-exclamation.svg'
import IconChevronLeft from 'assets/icons/chevron-left.svg'
import IconEnvelope from 'assets/icons/envelope.svg'

// Logos

export default {
  name: 'TheHeader',
  components: {
    IconExclamation,
    IconEnvelope,
    IconChevronLeft,
    MetaNav,
    Logo,
    Logopausentaste,
    Logo1000grad,
    ChatIcon,
    LanguageSwitch
  },
  mixins: [userActions],
  data () {
    return {
      loadLogoFailed: false
    }
  },
  computed: {
    ...mapGetters(['offcanvasOpen', 'chatDialogOpen', 'dialogOpen', 'messageEditorOpen', 'userInfo', 'theme', 'chatIsOpen', 'newChatMessage', 'counselingCenterId', 'counselingCenters', 'languages', 'language', 'chatIsDisabled']),
    logo () {
      if (['pausentaste', '1000grad'].includes(this.theme)) {
        return 'Logo' + this.theme
      }
      return null
    },
    counselingLogoLink () {
      if (!this.counselingCenterId) {
        return null
      }
      return counselingCenterLogoUrl(this.counselingCenterId)
    },
    counselingCenterName () {
      if (!this.counselingCenterId) {
        return ''
      }
      const center = this.counselingCenters.filter((counselingCenter) => counselingCenter.id === this.counselingCenterId).pop()
      return center && center.name ? center.name : ''
    },
    mappedLanguages () {
      return this.languages.map(lang => ({ value: lang, label: this.$t('system.languages.' + lang) }))
    }
  },
  watch: {
    counselingCenterId () {
      this.loadLogoFailed = false
    }
  },
  methods: {
    ...mapActions(['setOffcanvas', 'setDialog', 'storeDialog', 'switchLanguage']),
    toggleOffcanvas () {
      this.setOffcanvas(!this.offcanvasOpen)
    },
    logoutAndCatchFire () {
      this.__user__logout(false)
      location.href = 'https://www.startpage.com'
    },
    isThemePausentasteActive () {
      return VueCookie.get('theme') && VueCookie.get('theme') === 'pausentaste'
    },
    isTheme1000GradActive () {
      return VueCookie.get('theme') && VueCookie.get('theme') === '1000grad'
    },
    newLanguage(lang) {
      this.switchLanguage({ lang, vm: this })
    }
  }
}
</script>

<style lang="scss">
@import '_assets/header';
</style>
