<template>
  <el-dropdown
    v-if="languages.length > 1"
    class="e-language-switch"
    trigger="click"
    @command="newLanguage"
  >
    <template v-if="small">
      <div class="e-language-switch__button-small">
        <img :src="icon" />
        <IconArrowDown class="e-language-switch__dropdown-icon" />
      </div>
    </template>
    <template v-else>
      <div class="e-language-switch__button">
        <span class="e-language-switch__icon">
          {{ languages.filter((aLanguage) => aLanguage.value === language ).pop().label }}
        </span>
        <IconArrowDown class="e-language-switch__dropdown-icon" />
      </div>
    </template>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="languageItem in availableLanguages"
        :key="languageItem.value"
        :command="languageItem.value"
        class="e-language-switch__option"
        :class="{'e-language-switch__option--active': languageItem.value === language}"
        :disabled="languageItem.value === language"
      >{{ languageItem.label }}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
// import IconLanguage from 'assets/icons/cog.svg'
import IconArrowDown from 'assets/icons/arrow-carrot-down.svg'
import IconLanguage from 'assets/bitmaps/icon20x24px-exported.jpg'

export default {
  name: 'LanguageSwitch',
  components: { IconArrowDown },
  props: {
    language: {
      type: String
    },
    languages: {
      type: Array
    },
    small: {
      type: Boolean
    }
  },
  computed: {
    availableLanguages () {
      if (this.small) {
        return this.languages
      }
      return this.languages.filter((aLanguage) => aLanguage.value !== this.language)
    }
  },
  data () {
    return {
      icon: IconLanguage
    }
  },
  methods: {
    newLanguage: async function (lang) {
      this.$emit('switch-language', lang)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/LanguageSwitch';
</style>
