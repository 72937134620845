<template>
  <img :src="logo" alt="kjt Onlineberatung" class="logo-img" />
</template>

<script>
export default {
  name: 'Logo',
  data: () => ({
    logo: require(`assets/bitmaps/logo-kjt.png`)
  })
}
</script>
<style lang="scss">
header .e-brand {
  display: flex;
  width: 180px;
  padding: 0;
  border: 0;
  margin: 0 auto;
  background: transparent;

  @include break(l) {
    float: left;
    margin: 0;
    width: 172px;
    margin-top: 13px;
  }

  .e-overall--login & {
    float: left;
    flex-direction: column;
    margin: 0;

    :first-child {
      margin-bottom: 10px;
      margin-top: 16px;
    }

    @include break(m) {
      width: 172px;
    }
  }

  .e-overall--loggedin & {
    align-items: center;
  }

  .logo-img {
    display: block;
    width: 114px;
    height: auto;

    @include break(s) {
      width: 132px;
    }
  }
}
</style>
