<template>
  <footer class="e-footer">
    <!-- <span v-show="!hideLogos" class="e-brand"> -->
    <!-- <Logo class="e-brand--white" /> -->
    <!-- <LogoOrig class="e-brand--orig" /> -->
    <!-- </span> -->
    <supporters class="e-footer__supporters" />
  </footer>
</template>

<script>
import Supporters from 'atoms/Supporters/Supporters'

// Icons
// import LogoOrig from 'assets/icons_orig/logo-kinderschutz.svg'

export default {
  name: 'TheFooter',
  components: {
    // Logo,
    // LogoOrig,
    Supporters
  }
  // props: {
  //   hideLogos: {
  //     type: Boolean,
  //     default: false
  //   }
  // }
}
</script>

<style lang="scss" scoped>
@import '_assets/footer';
</style>
