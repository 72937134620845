<template>
  <span class="e-chat-icon">
    <IconChat />
    <el-tooltip
      v-if="showNotification"
      :content="notificationMessage"
      effect="dark"
      placement="top"
    >
      <div class="e-badge e-badge--chat-icon" />
    </el-tooltip>
  </span>
</template>
<script>

import IconChat from 'assets/icons/chat.svg'

export default {
  name: 'ChatIcon',
  components: {
    IconChat
  },
  props: {
    showNotification: {
      default: false,
      type: Boolean
    },
    notificationMessage: {
      default: '',
      type: String
    }
  }
}
</script>
<style lang="scss" scoped>
@import '_assets/ChatIcon';
</style>
