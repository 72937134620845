<template>
  <nav class="e-offcanvas__nav e-offcanvas__nav--account">
    <h4 class="e-offcanvas__nav-title">
      {{ $t('system.navigation.myProfile') }}
    </h4>
    <ul>
      <li>
        <router-link :to="{ name: 'account' }">
          {{ $t('system.navigation.account') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'changePassword' }">
          {{ $t('system.navigation.changePassword') }}
        </router-link>
      </li>
    </ul>
    <ul>
      <li>
        <router-link
          v-if="userInfo.feedbackToolEnabled"
          :to="{ name: 'feedback' }"
        >
          {{ $t('system.navigation.feedback') }}
        </router-link>
        <span
          v-else
        >
          {{ $t('system.navigation.feedback') }}
        </span>
      </li>
    </ul>
    <ul>
      <li>
        <button
          type="button"
          @click="__user__confirmLogout"
        >
          {{ $t('system.navigation.logout') }}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { userActions } from 'mixins'

export default {
  name: 'NavigationAccount',
  mixins: [userActions],
  computed: mapGetters(['userInfo'])
}
</script>
