<template>
  <div class="e-supporters">
    <ul>
      <li>{{ $t('system.supportedBy') }}</li>
      <li><IconCaritas class="e-supporters__logo Caritas" /></li>
      <li><IconFondation class="e-supporters__logo Fondation" /></li>
      <li><IconLigueMedico class="e-supporters__logo LigueMedico" /></li>
      <li><IconCroixRouge class="e-supporters__logo CroixRouge" /></li>
      <li>{{ $t('system.supportedByAddText') }}</li>
    </ul>
  </div>
</template>

<script>
import IconCroixRouge from 'assets/icons_orig/logo-croix-rouge-luxembourgeoise.svg'
import IconFondation from 'assets/icons_orig/logo-FONDATION-KANNERSCHLASS.svg'
import IconLigueMedico from 'assets/icons_orig/logo-Ligue-Médico-Social.svg'
import IconCaritas from 'assets/icons_orig/logo-cjf.svg'
// import Logo from 'atoms/Logo/Logo'

export default {
  name: 'SupportersBar',
  components: {
    IconCroixRouge,
    IconFondation,
    IconLigueMedico,
    IconCaritas
    // Logo
  }
}
</script>

<style lang="scss">
@import '_assets/supporters';
</style>
