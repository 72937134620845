<template>
  <a
    href="https://www.pausentaste.de/"
    target="_blank"
  >
    <Logo />
  </a>
</template>

<script>

import Logo from 'assets/icons/logo-pausentaste.svg'

export default {
  name: 'LogoPausentaste',
  components: {
    Logo
  }
}
</script>
<style lang="scss" scoped>
.e-brand-secondary {
  min-width: 90px;
  height: 25px;
  vertical-align: middle;
  cursor: pointer;

  svg {
    height: 100%;
    width: auto;
    filter: invert(1);
    @include break(l) {
      filter: none;
    }
  }

  @include break(s) {
    min-width: 110px;
  }

  @include break(m) {
    min-width: 70px;
  }

  @include break(ml) {
    min-width: 80px;
  }

  // other pages
  .e-overall--loggedin & {
    min-width: 80px;
    width: 75px;
    height: auto;
    margin-left: 10px;

    svg {
      filter: none;
      @include break(xs) {
        width: 100px;
        width: auto;
      }

      @include break(sm) {
        height: 25px;
      }
  }

    @include break(s) {
      min-width: 110px;
    }

    @include break(m) {
      min-width: 80px;
      margin-left: 5px;
    }

    @include break(ml) {
      min-width: 110px;
      margin-left: 10px;
    }
  }
}

</style>
